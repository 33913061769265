<template>
  <div>
    <div class="container">
      <div class="row">
        <app-bar
          title="Dompetku"
          :isBgTransparent="isMobile"
          :noDivider="true"
          previous-link="/"
          class="p-0"/>
      </div>
    </div>
    <header class="d-md-none mb-3">
      <div class="background-header bg-info-500" />
      <div class="content container d-flex flex-column justify-content-end">
        <div class="row">
          <div class="col d-flex align-items-end justify-content-between">
            <div class="wallet-group d-flex flex-column gap-2">
              <img
                src="@/assets/icon/wallet.svg"
                alt="Icon Wallet"
                class="icon-wallet">
              <span class="wallet-balance fw-700 text-white">Rp. 24.320.323</span>
            </div>
            <router-link
              :to="{ name: 'Topup' }"
              class="btn btn-topup btn-orange">
              <span class="fw-600 fs-12 lh-18 text-white">Isi Saldo</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="info container px-3">
        <div class="row">
          <div class="col">
            <div class="card shadow border-0">
              <div class="card-body d-flex align-items-center justify-content-between p-0">
                <div class="info-group d-flex align-items-center gap-2">
                  <img src="@/assets/icon/like-shapes.svg" alt="Icon Like">
                  <div class="value-wrapper d-flex flex-column">
                    <div class="d-flex align-items-center gap-1">
                      <span class="fw-700 fs-12 lh-14 color-neutral-900">200</span>
                      <span class="fw-400 fs-10 lh-12 color-neutral-600">Point</span>
                    </div>
                    <label class="fw-400 fs-10 lh-12 color-neutral-600">
                      Friend Member
                    </label>
                  </div>
                </div>
                <img
                  src="@/assets/icon/arrow-right-outline-blue.svg"
                  alt="Icon Arrow"
                  class="icon-arrow">
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="px-3 pt-4">
      <div class="fw-700 fs-14 color-neutral-900 mb-3">Riwayat Transaksi</div>
      <!-- <div class="item-content">
        <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
            <div class="not-empty d-flex flex-column align-items-center pt-3">
                <div class="mb-4">
                  <img src="@/assets/icon/empty.png" alt="">
                </div>
                <div class="fm-poppins text-title-active fw-600 fs-32">Loading..</div>
            </div>
        </div>
      </div> -->

      <!-- <div class="item-content">
        <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
          <div class="not-empty d-flex flex-column align-items-center pt-3">
            <img
              src="@/assets/icon/transaction.svg"
              alt="Icon Transaction"
              class="mb-3">
            <div class="title fw-700 color-neutral-900">Tidak ada riwayat transaksi</div>
            <p class="desc fw-400 color-neutral-600 text-center">Anda belum mempunyai riwayat transaksi apapun.</p>
          </div>
        </div>
      </div> -->

      <div class="item-content mb-3">
        <span class="title fw-700 fs-12 color-neutral-900">Hari ini</span>
        <div class="list">
          <div
            v-for="item in dummy"
            :key="item"
            class="item d-flex align-items-center justify-content-between">
            <div class="item-group d-flex align-items-center gap-2 py-2">
              <img
                src="@/assets/icon/drugs.svg"
                alt="Icon Item"
                class="item-image">
              <div class="d-flex flex-column gap-1">
                <div class="item-name fw-700 fs-12 color-neutral-900">
                  {{ item.nama }}
                </div>
                <p class="item-time fw-400 fs-10 lh-12 color-neutral-600 mb-0">{{ item.waktu }} WIB</p>
              </div>
            </div>
            <div
              class="item-price fw-600 fs-12"
              :class="item.type === 'minus' ? 'color-danger-500' : 'color-success-500'">
              {{ checkItemType(item.type, item.value) }}
              <span v-if="item.type === 'point'" class="fm-nunito color-neutral-600">Point</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import { computed } from 'vue'

export default {
  components: {
    AppBar
  },
  setup () {
    const isMobile = computed(() => window.screen.width < 576)
    const dummy = [
      {
        nama: 'Skrening Narkoba',
        waktu: '14:00',
        type: 'minus',
        value: 345000
      },
      {
        nama: 'Agregasi Trombosit',
        waktu: '10:00',
        type: 'point',
        value: 10
      },
      {
        nama: 'Hadiah Point',
        waktu: '10:00',
        type: 'plus',
        value: 2300000
      }
    ]

    const checkItemType = (type, value) => {
      let output = null
      if (type === 'minus') {
        output = '-Rp.' + value.toLocaleString('id-ID')
      } else if (type === 'plus') {
        output = '+Rp.' + value.toLocaleString('id-ID')
      } else {
        output = '+' + value
      }

      return output
    }

    return {
      isMobile,
      dummy,
      checkItemType
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  height: 200px;
  position: relative;

  .background-header {
    width: 100%;
    height: 200px;
    position: absolute;
    z-index: 1;
  }

  .icon-wallet {
    width: 40px;
    height: 40px;
  }

  .icon-arrow {
    width: 8px;
    height: 17px;
  }

  .btn-topup {
    padding: 6px 14px;
  }

  .wallet {
    &-balance {
      font-size: 24px;
      line-height: 34px;
    }
  }

  .content {
    position: absolute;
    z-index: 2;

    @media (max-width: 580px) {
      height: 27vh;
    }
  }

  .info {
    position: absolute;
    z-index: 3;

    @media (max-width: 580px) {
      bottom: -25px;
    }

    .card {
      padding: 12px;
      border-radius: 12px;
    }
  }
}

.not-empty {
  .title {
    font-size: 18px;
    line-height: 28px;
  }

  .desc {
    font-size: 14px;
    line-height: 24px;
    max-width: 255px;
  }
}

.item-content {
  .item {
    box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;

    &-image {
      width: 38px;
      height: 38px;
      object-fit: cover;
    }
  }

  .item:last-child {
     box-shadow: none;
  }
}
</style>
